import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {getInformation} from "../util/util";
import ShareLink from 'react-facebook-share-link'
const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
}));
const Victord2exp = () => {
    const classes = useStyles();
    const [information,setInformation] = useState({});

    useEffect(()=>{
        getInformation().then(info=>{
            if(JSON.stringify(info) !== JSON.stringify(information)){
                setInformation(info);
            }
        });
    },[information])
    const renderInformation =  () => {
        const styleInformation = {backgroundColor: '#000000'};
        return(
            <div style={styleInformation}>
                {JSON.stringify(information)}
            </div>
        );
    }
    return (
        <section className="App" >
            <img src={"./img/logo-blanco.png"} className={"imgVictord2exp"} />
            <ShareLink link='https://www.facebook.com/victord2exp/'>
                {link => (
                    <a href={link} target='_blank'>Share this on Facebook</a>
                )}
            </ShareLink>
            {renderInformation()}
        </section>
    )
}

export default Victord2exp