import getMAC, { isMAC } from 'getmac'
const browserInfo = require('browser-info');
const macaddress = require('macaddress');
const publicIp = require('public-ip');

export const getInformation = async () => {
    //const mac = getMAC('eth0');
    /*macaddress.one(function (err, mac) {
        console.log("Mac address for this host: %s", mac);
    });
    macaddress.all(function (err, all) {
        console.log(JSON.stringify(all, null, 2));
    });
    macaddress.all().then(function (all) {
        console.log(JSON.stringify(all, null, 2));
    });*/
    /*macaddress.one().then(function (mac) {
        console.log("Mac address for this host: %s", mac);
    });
    macaddress.one(() => {
        console.log("test01");
    });*/
    macaddress.one(function (err, mac) {
        console.log("Mac address for this host: %s", mac);
    });

    const IP = await publicIp.v4();
    return {
        IP: IP,
        //mac:getMAC()
        ...browserInfo()
    }
}